import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Grid, Paper } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  pageContent: {
    padding: theme.spacing(3),
    width: "40vw",
  },
  "@media only screen and (max-width: 600px)": {
    pageContent: {
      padding: theme.spacing(3),
      width: "80vw",
    },
  },
}));

export const ConfirmPassword = () => {
  const classes = useStyles();
  const [values, setValues] = useState();
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const navigate = useNavigate();

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("username" in fieldValues)
      temp.username = fieldValues.username ? "" : "Това поле е задължително";
    if ("password" in fieldValues)
      temp.password = fieldValues.password ? "" : "Това поле е задължително";
    if ("code" in fieldValues)
      temp.code = fieldValues.code ? "" : "Това поле е задължително";

    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await Auth.forgotPasswordSubmit(
      values.username,
      values.code,
      values.password
    )
      .then((data) => {
        console.log(data);
        navigate("/");
      })
      .catch((err) => {
        setError(err);
        setValues();
        setTimeout(() => {
          setError("");
        }, 5000);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Paper className={classes.pageContent}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Вход
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form} noValidate>
            {error && (
              <Alert severity="error">
                Невалидни потребителско име или парола
              </Alert>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Потребителлско име"
              name="username"
              autoFocus
              onChange={handleInputChange}
              {...(error && { error: true, helperText: error })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Парола"
              type="password"
              id="password"
              onChange={handleInputChange}
              {...(error && { error: true, helperText: error })}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="Код за потвърждение"
              type="code"
              id="code"
              onChange={handleInputChange}
              {...(error && { error: true, helperText: error })}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Изпрати
            </Button>
          </form>
        </Paper>
      </div>
    </Container>
  );
};
