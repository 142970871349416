import { Amplify, Hub } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { SignIn } from "./components/auth/SignIn";
import { ForgotPassword } from "./components/auth/ForgotPassword";
import { makeStyles } from "@material-ui/core";
import { Routes, Route } from "react-router-dom";
import awsExports from "./aws-exports";
import { useEffect, useState } from "react";
import { ConfirmPassword } from "./components/auth/ConfirmPassword";
import { HomePage } from "./Pages/HomePage";
import OrdersForm from "./Pages/OrdersForm";
//import { CustomNavigation } from "../components/general/CustomNavigation";
import { SideBarDrawer } from "./ComponentsProd/SideBarDrawer/SideBarDrawer";

const useStyles = makeStyles({
  button: {
    width: "100%",
  },
});

Amplify.configure(awsExports);

export default function App() {
  const classes = useStyles();
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    Hub.listen("auth", (e) => {
      if (e.payload.event === "signIn") {
        setCurrentUser(e.payload.data);
      }
      if (e.payload.event === "signOut") {
        setCurrentUser("");
      }
    });
  }, []);

  return (
    <div className={classes.appMain}>
      <Routes>
        <Route
          exact
          path="/"
          element={currentUser ? <SideBarDrawer /> : <SignIn />}
        />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />

        <Route exact path="/confirmpassword" element={<ConfirmPassword />} />
        <Route exact path="/login" element={<SignIn />} />

        <Route path="*" element={<SideBarDrawer />} />
      </Routes>
    </div>
  );
}
//<Route exact path="/ordersform" element={<OrdersForm />} />
