import React, { useEffect, useState } from "react";
import OrdersForm from "./OrdersForm";
import {
  InputAdornment,
  makeStyles,
  Paper,
  TableSortLabel,
  Toolbar,
} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import { Controls } from "../components/controls/controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import deLocale from "date-fns/locale/bg";
import axios from "axios";
import { API, Auth } from "aws-amplify";
import xml2js from "xml2js";
import unorm from "unorm";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },

  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.light,
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    position: "absolute",
    right: "10px",
    padding: "15px",
  },
}));

const headCells = [
  { id: "id", label: "№" },
  { id: "orderStatus", label: "Статус" },
  { id: "executedBy", label: "Изпълнява се от" },
  { id: "exitDate", label: "Дата на издаване" },
  { id: "product_name1", label: "Име на продукт" },
  { id: "shippingFirstName", label: "Име на клиент" },
  { id: "shippingLastName", label: "Фамилия на клиент" },
  { id: "actions", label: "Действия", disableSorting: true },
];

const Orders = (props) => {
  const classes = useStyles();
  const pages = [5, 10, 15];
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[1]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [readOnly, setRedOnly] = useState(false);
  const [details, setDetails] = useState(false);
  const [maxId, setMaxId] = useState();
  const [onlineOrders, setOnlineOrders] = useState([]);
  const [ordersForUpload, setOrdersForUpload] = useState();
  const [exitDate, setExitDate] = useState();
  const navigate = useNavigate();

  const { employees } = props;

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el1) => el1[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const ordersAfterPagingandSorting = () => {
    return stableSort(filterFn.fn(orders), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleSearch = (e) => {
    const target = e.target;

    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              getStatusNameById(x.orderStatus)
                .toLowerCase()
                .includes(target.value.toLowerCase()) ||
              x.exitDate.includes(target.value) ||
              x.id.includes(target.value) ||
              x.executedBy.toLowerCase().includes(target.value) ||
              x.products[0].productName.toLowerCase().includes(target.value) ||
              x.shippingFirstName.toLowerCase().includes(target.value) ||
              x.shippingLastName.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const openDetailsPopup = (item) => {
    setRecordForEdit(item);
    localStorage.setItem("currentPath", "/ordersform");
    setDetails(true);
    setRedOnly(true);
    navigate("/");
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    localStorage.setItem("currentPath", "/ordersform");
    setRedOnly(false);
    navigate("/");
  };

  const addOrder = async (orderObj) => {
    const apiName = "ordersapi";
    const path = "/orders";
    const myInit = {
      body: {
        ...orderObj,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit)
      .then((res) => {
        fetchOrders();
        setTimeout(() => {
          localStorage.setItem("currentPath", "/");
          navigate("/");
        }, 1500);
      })
      .catch((error) => console.log(error.response.data));
  };

  //get orders

  const getAll = async (url) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        //Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    return await axios.get(url, config).then((response) => response.data);
  };

  const fetchOrders = async () => {
    const apiName = "ordersapi";
    const path = "/orders";
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        Accept: "application/json",
      },
    };

    return await API.get(apiName, path, myInit)
      .then((res) => {
        setOrders(res);
      })
      .catch((e) => console.log(e));
  };
  const highestId = (orders) => {
    const ids = orders.map((order) => parseInt(order.id, 10));
    const highestId = Math.max(...ids);
    return highestId;
  };

  const deleteOrder = async (id) => {
    const apiName = "ordersapi";
    const path = `/orders/${id}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await API.del(apiName, path, myInit)
      .then((res) => {
        fetchOrders();
      })
      .catch((error) => console.log(error.response.data));
  };

  const updateOrder = async (ordersObj) => {
    const apiName = "ordersapi";
    const path = "/orders";
    const myInit = {
      body: {
        ...ordersObj,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.put(apiName, path, myInit)
      .then((res) => {
        setRecordForEdit(null);
        fetchOrders();
        setTimeout(() => {
          localStorage.setItem("currentPath", "/");
          navigate("/");
        }, 1500);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    fetchOrders();
    setMaxId(highestId(orders));
  }, []); //

  useEffect(() => {
    const parser = new xml2js.Parser();

    getAll("http://www.tortibrimeks.bg/export/orders.xml")
      .then((response) => {
        parser.parseString(response, function (err, result) {
          const idArr = orders.map((x) => x.onlineOrderId);
          const ids = orders.map((order) => parseInt(order.id, 10));
          const highestId = Math.max(...ids);
          const nextId = highestId + 1;
          const convertDate = (custDate) => {
            const k = custDate.split(".");
            const p = `${k[2]}-${k[1]}-${k[0]}`;
            return p;
          };
          result.itemlist.item.map((x, i) => {
            if (!idArr.includes(x.order_id[0])) {
              setOrdersForUpload({
                ["comment"]: x.comment[0], //should be result.itemlist.item[index]
                ["createdBy"]: "",
                ["executedBy"]: "",
                ["id"]: nextId.toString(), //generate
                ["email"]: x.email[0],
                ["phone"]: x.telephone[0],
                ["orderStatus"]: "received",
                ["paymentMethod"]: x.payment_method[0],
                ["partialPayment"]: 0,
                ["shippingAddress"]: x.shipping_address_1[0],
                ["onlineOrderId"]: x.order_id[0],
                ["shippingFirstName"]: x.shipping_firstname[0],
                ["shippingLastName"]: x.shipping_lastname[0],
                ["shippingMethod"]: unorm
                  .nfc(x.shipping_method[0])
                  .toString()
                  .includes(unorm.nfc("наша"))
                  ? "takeAway"
                  : "delivery",
                ["shippingValue"]: parseFloat(x.shipping_value[0]),
                ["total"]: parseFloat(x.total_value[0]),
                ["createdAt"]: x.date_added[0],
                ["exitDate"]: exitDate || "",
              });
              x.products[0].product.map((product, index) => {
                ordersForUpload["products"] = [
                  {
                    ["productName"]: product.name[0],
                    ["productModel"]: product.model[0],
                    ["productQuantity"]: parseInt(product.quantity[0]),
                    ["productPrice"]: parseFloat(product.price[0]),
                    ["productSum"]: parseFloat(product.total[0]),
                  },
                ];
                product.option.map((option) => {
                  console.log(
                    unorm.nfc(option.name[0]) === unorm.nfc("Пълнеж на торта")
                      ? option.value[0]
                      : ""
                  );

                  ordersForUpload["products"][index] = {
                    ...ordersForUpload["products"][index],
                    ["productText"]:
                      unorm.nfc(option.name[0]) ===
                      unorm.nfc("Надпис (по-желание)")
                        ? option.value[0]
                        : "",
                  };

                  ordersForUpload["products"][index] = {
                    ...ordersForUpload["products"][index],
                    ["productFilling"]:
                      unorm.nfc(option.name[0]) === unorm.nfc("Пълнеж на торта")
                        ? option.value[0]
                        : "",
                  };

                  ordersForUpload["products"][index] = {
                    ...ordersForUpload["products"][index],
                    ["productSize"]:
                      unorm.nfc(option.name[0]) ===
                      unorm.nfc("Изберете размер на торта")
                        ? parseInt(option.value[0])
                        : "",
                  };

                  setExitDate(
                    unorm.nfc(option.name[0]) ===
                      unorm.nfc(
                        "Изберете дата на доставка/получаване - ДЕН/МЕСЕЦ"
                      )
                      ? convertDate(option.value[0])
                      : ""
                  );
                });
              });
            }
          });
          //addOrder(ordersForUpload);
          //console.log(ordersForUpload);
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []); //ordersForUpload

  //todo add on suggestion input for product name and product model which will autofil price product model and product name

  const statusList = [
    { id: "received", title: "Приета" },
    { id: "wip", title: "Изпълнява се" },
    { id: "completed", title: "Изпълнена" },
  ];

  const getStatusNameById = (statusId) => {
    for (const element of statusList) {
      if (element.id === statusId) return element.title;
    }
  };

  return (
    <div>
      <Paper className={classes.pageContent}>
        {localStorage.getItem("currentPath") === "/ordersform" ? (
          <OrdersForm
            recordForEdit={recordForEdit}
            getStatusNameById={getStatusNameById}
            readOnly={readOnly}
            editOne={updateOrder}
            addOne={addOrder}
            statusList={statusList}
            maxId={maxId}
            employees={employees}
            orders={orders}
          />
        ) : (
          <div>
            <Toolbar>
              <Controls.Input
                label="Търсене"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
              {employees ? (
                ""
              ) : (
                <Controls.Button
                  className={classes.newButton}
                  text="Добави"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    localStorage.setItem("currentPath", "/ordersform");
                    setRecordForEdit(null);
                    setRedOnly(false);
                    navigate("/");
                  }}
                />
              )}
            </Toolbar>
            <Table
              className={classes.table}
              stickyHeader
              aria-label="sticky table"
            >
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.disableSorting ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          onClick={() => {
                            handleSortRequest(headCell.id);
                          }}
                          direction={orderBy === headCell.id ? order : "asc"}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {ordersAfterPagingandSorting().map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{getStatusNameById(item.orderStatus)}</TableCell>
                    <TableCell>{item.executedBy}</TableCell>

                    <TableCell>
                      {format(new Date(item.exitDate), "eeee dd MMM HH:mm", {
                        locale: deLocale,
                      })}
                    </TableCell>
                    <TableCell>{item.products[0].productName}</TableCell>
                    <TableCell>{item.shippingFirstName}</TableCell>
                    <TableCell>{item.shippingLastName}</TableCell>
                    <TableCell>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          openDetailsPopup(item);
                          setRecordForEdit(item);
                        }}
                      >
                        <MoreHorizOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Controls.ActionButton>
                      {employees ? (
                        ""
                      ) : (
                        <Controls.ActionButton
                          color="secondary"
                          onClick={() => {
                            deleteOrder(item.id);
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </Controls.ActionButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              page={page}
              rowsPerPageOptions={pages}
              rowsPerPage={rowsPerPage}
              count={orders.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Orders;
