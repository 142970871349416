import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const SideBarDrawerUtils = () => {
  //const { setOpen, navigate } = SideBarDrawer;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = async () => {
    await Auth.signOut()
      .then((res) => {
        navigate("/");
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  };

  return {
    setOpen,
    open,
    handleDrawerOpen,
    handleDrawerClose,
    logoutHandler,
  };
};
