import { React, useState, useEffect } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "date-fns";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import {
  initialValues,
  numberOfPiecesList,
  deliveryMethodList,
  takeAwayLocationList,
  paymentMethodList,
  statusList,
  fillingItems,
  categoryList,
  executionGroupList,
} from "./ItemsFormData";
import { useNavigate } from "react-router-dom";
import { Form } from "./Form";
import { CustomRadioGroup } from "../CustomComponents/CustomRadioGroup";
import { CustomSelect } from "../CustomComponents/CustomSelect";
import { CustomInput } from "../CustomComponents/CustomInput";
import { CustomDatePicker } from "../CustomComponents/CustomDatePicker";
import { CustomActionButton } from "../CustomComponents/CustomActionButton";
import { CustomButton } from "../CustomComponents/CustomButton";
import { ItemsFormUtils } from "./ItemsFormUtils";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Storage } from "aws-amplify";
import { ItemsPageUtils } from "../ItemsPage/ItemsPageUtils";
import xml2js from "xml2js";

const useStyles = makeStyles((theme) => ({
  input: {
    display: "none",
  },
}));
export const ItemsForm = (props) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validate,
    highestId,
    sumTotal,
    productPrice,
  } = ItemsFormUtils(initialValues);

  const {
    readOnly,
    recordForEdit,
    setRecordForEdit,
    updateItem,
    createItem,
    items,
    currUser,
    group,
    setItems,
    fetchItems,
  } = props;

  const { getAll } = ItemsPageUtils();

  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [menu, setMenu] = useState([]);

  const registerHandler = async (e) => {
    e.preventDefault();
    values.createdBy = currUser;

    if (validate()) {
      try {
        if (values.id === "") {
          const nextId = highestId(items) + 1;
          values.id = nextId.toString();
          createItem(values);
          fetchItems("ordersapi", "/orders", setItems);
          setSuccessMessage("Успешна регистрация на поръчка");
          setTimeout(() => {
            navigate("/");
          }, 1500);
        } else {
          values.executedBy = "";
          values.executedBy = currUser;
          try {
            updateItem(values);
            setSuccessMessage("Записът за поръчката е обновен");
            fetchItems("ordersapi", "/orders", setItems);
            setRecordForEdit(null);
            setTimeout(() => {
              navigate("/");
            }, 1500);
          } catch (err) {
            console.log(err);
            setError(err.response);
            setTimeout(() => {
              setError("");
            }, 5000);
          }
        }

        setSuccess("success");
        resetForm();

        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } catch (error) {
        setError(error.response);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  useEffect(() => {
    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
    }
    //

    const parser = new xml2js.Parser({ trim: true });
    getAll("https://www.tortibrimeks.bg/export/brimeksp.xml").then(
      (response) => {
        parser.parseString(response, function (err, result) {
          const i = result.itemlist.item.map((item) => ({
            title: item.name_bg[0],
            model: item.model[0].split("-")[0].replace(/\s/g, ""),
            price: parseFloat(item.price[0]),
          }));

          setMenu(i);
        });
      }
    );

    // eslint-disable-next-line
  }, [recordForEdit !== null ? "" : values]); //values

  return (
    <Form onSubmit={registerHandler}>
      {success && <Alert severity="success">{successMessage}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}{" "}
      <Typography variant="h4" gutterBottom>
        Детайли за поръчката
      </Typography>
      <Box m={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CustomRadioGroup
            name="paymentMethod"
            label="Статус на плащане"
            value={values.paymentMethod}
            onChange={handleInputChange}
            items={paymentMethodList}
            readOnly={readOnly || group.includes("employees")}
          />
          <CustomRadioGroup
            name="shippingMethod"
            label="Метод за доставка"
            value={values.shippingMethod}
            onChange={handleInputChange}
            items={deliveryMethodList}
            error={errors.shippingMethod}
            readOnly={readOnly || group.includes("employees")}
          />
          {values.shippingMethod === "takeAway" ? (
            <CustomSelect
              name="shippingAddress"
              label="Локация за взимане"
              value={values.shippingAddress}
              onChange={(e) => {
                setValues({
                  ...values,
                  shippingAddress: e.target.value,
                  shippingAbb:
                    e.target.value === "nishava"
                      ? "Н"
                      : e.target.value === "kostenski"
                      ? "К"
                      : e.target.value === "kableshkov"
                      ? "Т"
                      : "",
                });
              }}
              options={takeAwayLocationList}
              error={errors.shippingAddress}
              readOnly={readOnly || group.includes("employees")}
            />
          ) : values.shippingMethod === "delivery" ? (
            <CustomInput
              name="shippingAddress"
              label="Адрес за доставка"
              value={values.shippingAddress}
              onChange={(e) => {
                setValues({
                  ...values,
                  shippingAddress: e.target.value,
                  shippingAbb: "Д",
                });
              }}
              error={errors.shippingAddress}
              inputProps={{
                readOnly: Boolean(readOnly || group.includes("employees")),
              }}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomSelect
            name="orderStatus"
            label="Статус"
            value={values.orderStatus}
            onChange={handleInputChange}
            options={statusList}
            error={errors.orderStatus}
            readOnly={readOnly}
          />
          <CustomDatePicker
            name="exitDate"
            label="Дата на издаване"
            value={values.exitDate}
            onChange={handleInputChange}
            readOnly={readOnly || group.includes("employees")}
            error={errors.exitDate}
          />
          <CustomSelect
            name="executionGroup"
            label="За изпълнение от"
            value={values.executionGroup}
            onChange={handleInputChange}
            options={executionGroupList}
            error={errors.executionGroup}
            readOnly={readOnly || group.includes("employees")}
          />
        </Grid>
      </Grid>
      <Box m={2}>
        <Typography variant="h4" gutterBottom>
          Упътвания
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <CustomInput
            name="comment"
            label="Допълнителна информация"
            value={values.comment}
            onChange={handleInputChange}
            error={errors.comment}
            inputProps={{
              readOnly: Boolean(readOnly || group.includes("employees")),
            }}
            multiline
            minRows={4}
          />
        </Grid>
      </Grid>
      <Box m={2}>
        <Typography variant="h6" gutterBottom>
          Данни на клиента
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <CustomInput
            name="shippingFirstName"
            label="Име на клиент"
            value={values.shippingFirstName}
            onChange={handleInputChange}
            error={errors.shippingFirstName}
            inputProps={{
              readOnly: Boolean(readOnly || group.includes("employees")),
            }}
          />
          <CustomInput
            name="shippingLastName"
            label="Фамилия на клиент"
            value={values.shippingLastName}
            onChange={handleInputChange}
            error={errors.shippingLastName}
            inputProps={{
              readOnly: Boolean(readOnly || group.includes("employees")),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomInput
            name="email"
            label="Имейл"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
            inputProps={{
              readOnly: Boolean(readOnly || group.includes("employees")),
              disabled: Boolean(readOnly || group.includes("employees")),
            }}
          />
          <CustomInput
            name="phone"
            label="Телефон"
            value={values.phone}
            onChange={handleInputChange}
            error={errors.phone}
            inputProps={{
              readOnly: Boolean(readOnly || group.includes("employees")),
              disabled: Boolean(readOnly || group.includes("employees")),
            }}
          />
        </Grid>
      </Grid>
      {values.products?.map((productField, index) => {
        return (
          <div key={index}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box m={2}>
                  <Typography variant="h6" gutterBottom>
                    {"Данни за продукт" +
                      " " +
                      (isNaN(productField.productId + index)
                        ? 1
                        : productField.productId + index
                      ).toString()}
                  </Typography>
                </Box>
                {values.products[index].productCategory === "standard" ? (
                  <div>
                    <Autocomplete
                      id={"productName" + index.toString()}
                      disabled={readOnly || group.includes("employees")}
                      options={menu}
                      getOptionLabel={(option) => {
                        if (option.hasOwnProperty("title")) {
                          return option.title;
                        }
                        return option;
                      }}
                      onChange={(event, newValue) => {
                        const productValues = [...values.products];
                        productValues[index] = {
                          ...productValues[index],
                          productId: productField.productId + index,
                          productName: newValue.title,
                          productModel: newValue.model,
                          productSinglePrice: newValue.price,
                          productPrice:
                            Math.round(
                              values.products[index].productSize *
                                newValue.price *
                                100
                            ) / 100,
                          productSum:
                            Math.round(
                              values.products[index].productSize *
                                newValue.price *
                                values.products[index].productQuantity *
                                100
                            ) / 100,
                        };
                        const newValues = Object.assign({}, values, {
                          products: productValues,
                        });

                        setValues({
                          ...newValues,
                          total: sumTotal(productValues),
                        });
                      }}
                      value={values.products[index].productName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Име на продукта"
                          variant="outlined"
                        />
                      )}
                    />
                    <Autocomplete
                      id={"productModel" + index.toString()}
                      options={menu}
                      disabled={readOnly || group.includes("employees")}
                      getOptionLabel={(option) => {
                        if (option.hasOwnProperty("model")) {
                          return option.model;
                        }
                        return option;
                      }}
                      onChange={(event, newValue) => {
                        const productValues = [...values.products];
                        productValues[index] = {
                          ...productValues[index],
                          productId: productField.productId + index,
                          productName: newValue.title,
                          productModel: newValue.model,
                          productSinglePrice: newValue.price,
                          productPrice:
                            Math.round(
                              values.products[index].productSize *
                                newValue.price *
                                100
                            ) / 100,
                          productSum:
                            Math.round(
                              values.products[index].productSize *
                                newValue.price *
                                values.products[index].productQuantity *
                                100
                            ) / 100,
                        };
                        const newValues = Object.assign({}, values, {
                          products: productValues,
                        });
                        setValues({
                          ...newValues,
                          total: sumTotal(productValues),
                        });
                      }}
                      value={values.products[index].productModel}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Модел на продукта"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <CustomInput
                      id={"productName" + index.toString()}
                      label="Име на продукт"
                      value={values.products[index].productName}
                      onChange={(e) => {
                        const productValues = [...values.products];
                        productValues[index] = {
                          ...productValues[index],
                          productName: e.target.value,
                        };
                        const newValues = Object.assign({}, values, {
                          products: productValues,
                        });
                        setValues({
                          ...newValues,
                          total: sumTotal(productValues),
                        });
                      }}
                      error={errors.shippingLastName}
                      inputProps={{
                        readOnly: Boolean(
                          readOnly || group.includes("employees")
                        ),
                      }}
                    />
                    <CustomInput
                      id={"productSinglePrice" + index.toString()}
                      label="Единична цена на продукт"
                      type="number"
                      value={values.products[index].productSinglePrice}
                      onChange={(e) => {
                        const productValues = [...values.products];
                        productValues[index] = {
                          ...productValues[index],
                          productSinglePrice: parseFloat(e.target.value),
                          productPrice:
                            Math.round(
                              values.products[index].productSize *
                                parseFloat(e.target.value) *
                                100
                            ) / 100,
                          productSum:
                            Math.round(
                              values.products[index].productSize *
                                parseFloat(e.target.value) *
                                values.products[index].productQuantity *
                                100
                            ) / 100,
                        };
                        const newValues = Object.assign({}, values, {
                          products: productValues,
                        });
                        setValues({
                          ...newValues,
                          total: sumTotal(productValues),
                        });
                      }}
                      error={errors.shippingLastName}
                      inputProps={{
                        readOnly: Boolean(
                          readOnly || group.includes("employees")
                        ),
                        min: 0,
                        step: "0.1",
                      }}
                    />
                  </div>
                )}

                <Autocomplete
                  id={"producFilling" + index.toString()}
                  options={fillingItems}
                  disabled={readOnly || group.includes("employees")}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("title")) {
                      return option.title;
                    }
                    return option;
                  }}
                  onChange={(event, newValue) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productFilling: newValue.title,
                      productFillingAdditionalCost: newValue.additionalCost,
                      productPrice:
                        values.products[index].productPrice ===
                        values.products[index].productSinglePrice *
                          values.products[index].productSize *
                          values.products[index].productQuantity
                          ? Math.round(
                              values.products[index].productSize *
                                (values.products[index].productSinglePrice +
                                  newValue.additionalCost) *
                                100
                            ) / 100
                          : Math.round(
                              values.products[index].productSize *
                                values.products[index].productSinglePrice *
                                100
                            ) / 100,
                      productSum:
                        values.products[index].productPrice ===
                        values.products[index].productSinglePrice *
                          values.products[index].productSize *
                          values.products[index].productQuantity
                          ? Math.round(
                              values.products[index].productSize *
                                (values.products[index].productSinglePrice +
                                  newValue.additionalCost) *
                                values.products[index].productQuantity *
                                100
                            ) / 100
                          : Math.round(
                              values.products[index].productSize *
                                values.products[index].productSinglePrice *
                                values.products[index].productQuantity *
                                100
                            ) / 100,
                    };

                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });

                    setValues({
                      ...newValues,
                      total: sumTotal(productValues),
                    });
                  }}
                  value={values.products[index].productFilling}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Пълнеж на продукта"
                      variant="outlined"
                    />
                  )}
                />
                <CustomInput
                  name="productText"
                  label="Надпис по желание"
                  value={values.products[index].productText}
                  onChange={(e) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productText: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                  }}
                  error={errors[values.products[index].productText]}
                  inputProps={{
                    readOnly: Boolean(readOnly || group.includes("employees")),
                  }}
                  multiline
                  minRows={4}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box>
                  <CustomActionButton
                    color="primary"
                    onClick={() => {
                      const newProduct = {
                        productId: 1,
                        productModel: "",
                        productName: "",
                        productSize: 1,
                        productFilling: "",
                        productFillingAdditionalCost: 0,
                        productText: "",
                        productQuantity: 1,
                        productPrice: 0,
                        productSum: 0,
                        productSinglePrice: 0,
                        productCategory: "standard",
                        images: [],
                      };

                      setValues({
                        ...values,
                        products: [...values.products, newProduct],
                      });
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </CustomActionButton>
                  <CustomActionButton
                    color="secondary"
                    onClick={() => {
                      const vals = [...values.products];
                      if (values.products.length > 1) {
                        vals.splice(index, 1);
                        setValues({
                          ...values,
                          products: vals,
                        });
                      }
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </CustomActionButton>
                  <input
                    accept="image/*"
                    className={classes.input}
                    id="icon-button-file"
                    type="file"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const productValues = [...values.products];
                      productValues[index] = {
                        ...productValues[index],

                        images: [
                          ...productValues[index].images,
                          {
                            fileUrl: URL.createObjectURL(file),
                            file,
                            fileName: file.name,
                          },
                        ],
                      };
                      const newValues = Object.assign({}, values, {
                        products: productValues,
                      });
                      Storage.put(file.name, file)
                        .then(() => {
                          console.log("Successfull upload");
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                      setValues(newValues);
                    }}
                  />
                  <label htmlFor="icon-button-file">
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <PhotoCamera fontSize="large" />
                    </IconButton>
                  </label>
                </Box>
                <CustomRadioGroup
                  name="productCategory"
                  value={values.productCategory}
                  onChange={(e) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productCategory: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });

                    setValues(newValues);
                  }}
                  items={categoryList}
                  readOnly={readOnly || group.includes("employees")}
                />
                <Autocomplete
                  id={"productSize" + index.toString()}
                  options={numberOfPiecesList}
                  disabled={readOnly || group.includes("employees")}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("title")) {
                      return option.title;
                    }
                    return option.toString();
                  }}
                  onChange={(event, newValue) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productSize: newValue.id,
                      productPrice:
                        values.products[index].productPrice ===
                        values.products[index].productSinglePrice *
                          values.products[index].productSize *
                          values.products[index].productQuantity
                          ? Math.round(
                              newValue.id *
                                (values.products[index].productSinglePrice +
                                  values.products[index]
                                    .productFillingAdditionalCost) *
                                100
                            ) / 100
                          : Math.round(
                              newValue.id *
                                values.products[index].productSinglePrice *
                                100
                            ) / 100,
                      productSum:
                        values.products[index].productPrice ===
                        values.products[index].productSinglePrice *
                          values.products[index].productSize *
                          values.products[index].productQuantity
                          ? Math.round(
                              newValue.id *
                                (values.products[index].productSinglePrice +
                                  values.products[index]
                                    .productFillingAdditionalCost) *
                                values.products[index].productQuantity *
                                100
                            ) / 100
                          : Math.round(
                              newValue.id *
                                values.products[index].productSinglePrice *
                                values.products[index].productQuantity *
                                100
                            ) / 100,
                    };

                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });

                    setValues({
                      ...newValues,
                      total: sumTotal(productValues),
                    });
                  }}
                  value={values.products[index].productSize}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Брой парчета"
                      variant="outlined"
                    />
                  )}
                />
                <CustomInput
                  name="productQuantity"
                  label="Количество"
                  value={values.products[index].productQuantity}
                  type="number"
                  onChange={(e) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productQuantity: parseInt(e.target.value),
                      productSum:
                        values.products[index].productPrice ===
                        values.products[index].productSinglePrice *
                          values.products[index].productSize *
                          values.products[index].productQuantity
                          ? Math.round(
                              values.products[index].productSize *
                                (values.products[index].productSinglePrice +
                                  values.products[index]
                                    .productFillingAdditionalCost) *
                                parseInt(e.target.value) *
                                100
                            ) / 100
                          : Math.round(
                              values.products[index].productSize *
                                values.products[index].productSinglePrice *
                                parseInt(e.target.value) *
                                100
                            ) / 100,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues({
                      ...newValues,
                      total: sumTotal(productValues),
                    });
                  }}
                  error={errors[values.products[index].productQuantity]}
                  inputProps={{
                    readOnly: Boolean(readOnly || group.includes("employees")),
                    min: 0,
                  }}
                />

                <CustomInput
                  name="productPrice"
                  label="Цена на продукта"
                  value={values.products[index].productPrice || 0}
                  onChange={(e) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productPrice: parseFloat(e.target.value),
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                  }}
                  error={errors[values.products[index].productPrice]}
                  inputProps={{
                    readOnly: Boolean(true),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">лв</InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  name="productSum"
                  label="Сума"
                  value={values.products[index].productSum || 0}
                  onChange={(e) => {
                    const productValues = [...values.products];
                    productValues[index] = {
                      ...productValues[index],
                      productPrice: parseFloat(e.target.value),
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                  }}
                  error={errors[values.products[index].productPrice]}
                  inputProps={{
                    readOnly: Boolean(true),
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">лв</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}
      <Grid container>
        <Grid item xs={12} sm={6}>
          {!readOnly ? (
            <div>
              <CustomButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                text="Регистрирай"
              />
              <CustomButton
                variant="contained"
                color="default"
                size="large"
                text="Ресет"
                onClick={resetForm}
              />
            </div>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box m={3}>
            <CustomInput
              name="shippingValue"
              label="Сума за доставка"
              value={values.shippingValue}
              onChange={handleInputChange}
              error={errors.shippingValue}
              type="number"
              inputProps={{
                readOnly: Boolean(readOnly || group.includes("employees")),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
            <CustomInput
              name="partialPayment"
              label="Капаро"
              value={values.partialPayment}
              onChange={handleInputChange}
              error={errors.partialPayment}
              type="number"
              inputProps={{
                readOnly: Boolean(readOnly || group.includes("employees")),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
            <CustomInput
              name="total"
              label="Тотал"
              value={
                Math.round(
                  (values.total +
                    values.shippingValue * 1 -
                    values.partialPayment) *
                    100
                ) / 100
              }
              onChange={handleInputChange}
              error={errors.total}
              type="number"
              inputProps={{
                readOnly: Boolean(true),
                min: 0,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Form>
  );
}; //
