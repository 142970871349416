import { React, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PowerSettingsNewOutlinedIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { Routes, Route, Link } from "react-router-dom";
import { DrawerHeader, AppBar, Drawer } from "./SideBarDrawerStyles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { SideBarDrawerUtils } from "./SideBarDrawerUtils";
import { ItemsPage } from "../ItemsPage/ItemsPage";
import { ItemsListUtils } from "../ItemsList/ItemsListUtils";
import { ItemsForm } from "../ItemsForm/ItemsForm";
import { OrdersHeadCells } from "./SideBarDrawerData";
import { ItemsDetails } from "../ItemsDetails/ItemsDetails";
import { Auth } from "aws-amplify";
import useMediaQuery from "@mui/material/useMediaQuery";

export const SideBarDrawer = (props) => {
  const theme = useTheme();

  const [recordForEdit, setRecordForEdit] = useState({});
  const [readOnly, setRedOnly] = useState(false);
  const [items, setItems] = useState([]);
  const [group, setGroup] = useState([]);
  const [currUser, setCurrUser] = useState();
  const { updateItem, createItem, fetchItems } = ItemsListUtils();

  //
  const groups = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    return groups;
  };

  const getCurrUser = async () => {
    const tokens = await Auth.currentSession();
    const userName = tokens.getIdToken().payload["cognito:username"];
    return userName;
  };
  //

  const menus = group.includes("admins")
    ? [
        { id: "employees", title: "Служители" },
        { id: "", title: "Поръчки" },
        { id: "dashboard", title: "Дашборд" },
      ]
    : [{ id: "", title: "Поръчки" }];

  const { open, handleDrawerOpen, handleDrawerClose, logoutHandler } =
    SideBarDrawerUtils();

  useEffect(() => {
    groups()
      .then((res) => {
        res.includes("admins") ? setGroup("admins") : setGroup("employees");
      })
      .catch((e) => {
        console.log(e);
      });

    getCurrUser()
      .then((res) => {
        setCurrUser(res);
      })
      .catch((err) => console.log(err));
  }, [recordForEdit]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: "36px",
              marginBottom: "auto",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <IconButton
            onClick={logoutHandler}
            style={{ marginLeft: "auto", marginBottom: "auto" }}
          >
            <PowerSettingsNewOutlinedIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menus.map((text) => (
            <ListItem
              button
              key={text.title}
              component={Link}
              to={"/" + text.id}
            >
              <ListItemIcon>
                {text.id === "employees" ? (
                  <PeopleOutlineOutlinedIcon />
                ) : text.id === "" ? (
                  <ListAltOutlinedIcon />
                ) : (
                  <DashboardIcon />
                )}
              </ListItemIcon>
              <ListItemText primary={text.title} />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Routes>
          <Route path="/employees" element={<h1>Employees</h1>} />
          <Route
            path="/"
            element={
              <ItemsPage
                groups={groups}
                group={group}
                headCells={OrdersHeadCells}
                setRecordForEdit={setRecordForEdit}
                setRedOnly={setRedOnly}
                setItems={setItems}
                items={items}
              />
            }
          />
          <Route path="/dashboard" element={<h1>Dashboard</h1>} />
          <Route
            path="/ordersform"
            element={
              <ItemsForm
                updateItem={updateItem}
                createItem={createItem}
                readOnly={readOnly}
                recordForEdit={recordForEdit}
                setRecordForEdit={setRecordForEdit}
                currUser={currUser}
                group={group}
                items={items}
                setItems={setItems}
                fetchItems={fetchItems}
              />
            }
          />
          <Route
            path="/orderdetails"
            element={<ItemsDetails recordForEdit={recordForEdit} />}
          />
          <Route
            path="/editorder"
            element={
              <ItemsForm
                updateItem={updateItem}
                createItem={createItem}
                readOnly={readOnly}
                recordForEdit={recordForEdit}
                setRecordForEdit={setRecordForEdit}
                currUser={currUser}
                group={group}
                items={items}
                setItems={setItems}
                fetchItems={fetchItems}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  );
};
