import { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import { Grid, InputAdornment } from "@material-ui/core";
import { takeAwayLocationList } from "../ItemsForm/ItemsFormData";
import { format } from "date-fns";
import deLocale from "date-fns/locale/bg";

export const ItemsDetails = (props) => {
  const { recordForEdit } = props;
  const [local, setLocal] = useState({});

  useEffect(() => {
    console.log(recordForEdit);
    takeAwayLocationList.filter((location) => {
      if (location.id === recordForEdit.shippingAddress) {
        setLocal(location);
      }
    });
    console.log(recordForEdit);
  }, []);

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        Детайли за поръчката
      </Typography>

      <Grid container>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6" gutterBottom>
            Адрес на доставка: {local.title}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6" gutterBottom>
            Дата на доставка:
            {format(new Date(recordForEdit.exitDate), "eeee dd MMM HH:mm", {
              locale: deLocale,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Box m={2}>
        <Divider />
      </Box>

      <Typography variant="h6" gutterBottom>
        Данни на клиента
      </Typography>

      <Grid container>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">
            Име: {recordForEdit.shippingFirstName}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">Имейл: {recordForEdit.email}</Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">
            Фамилия: {recordForEdit.shippingLastName}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">Телефон: {recordForEdit.phone}</Typography>
        </Grid>
      </Grid>
      <Box m={2}>
        <Divider />
      </Box>
      <Grid container>
        <Typography variant="h6" gutterBottom>
          Упътвания
        </Typography>
        <Grid item xs={12} sm={12}>
          <Typography variant="h6">
            {recordForEdit.comment === ""
              ? "Няма оказани упътвания"
              : recordForEdit.comment}
          </Typography>
        </Grid>
      </Grid>
      <Box m={2}>
        <Divider />
      </Box>
      <Typography variant="h6" gutterBottom>
        Данни за продукти
      </Typography>
      {recordForEdit.products.map((product) => {
        return (
          <div>
            <Grid container>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Име на продукта: {product.productName}
                </Typography>
              </Grid>

              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Количество: {product.productQuantity}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Пълнеж: {product.productFilling}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Брой парчета: {product.productSize}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Надипис по желание: {product.productText}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">
                  Единична цена: {product.productPrice}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}></Grid>
              <Grid item xs={6} sm={6}>
                <Typography variant="h6">Сума: {product.productSum}</Typography>
              </Grid>
            </Grid>
            <Box m={2}>
              <Divider />
            </Box>
          </div>
        );
      })}
      <Grid container>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">
            Цена на доставка: {recordForEdit.shippingValue}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6}></Grid>
        <Grid item xs={6} sm={6}>
          <Typography variant="h6">
            Капаро: {recordForEdit.partialPayment}
          </Typography>
          <Typography variant="h6">Тотал: {recordForEdit.total}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};
//recordForEdit.shippingAddress
/*
{takeAwayLocationList.filter((location) => {
              return Object.keys(location).some((key) =>
                location[key]
                  .toLowerCase()
                  .includes("recordForEdit.shippingAddress")
              );
            })}


            takeAwayLocationList.filter((location) => {
        if (location.id === recordForEdit.shippingAddress) {
          setLoca(location);
        }
    }


    
*/
