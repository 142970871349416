export const OrdersHeadCells = [
  { id: "id", label: "№" },
  { id: "orderStatus", label: "Статус" },
  { id: "executedBy", label: "Изпълнява се от" },
  { id: "exitDate", label: "Дата на издаване" },
  { id: "product_name1", label: "Име на продукт" },
  { id: "shippingAbb", label: "Адрес" },
  { id: "executionGroup", label: "Екип" },
  { id: "actions", label: "Действия", disableSorting: true },
];
