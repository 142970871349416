import { useState } from "react";

import { API, Auth } from "aws-amplify";
import axios from "axios";

export const ItemsListUtils = () => {
  const pages = [5, 10, 15];
  const [orderBy, setOrderBy] = useState();
  const [order, setOrder] = useState();
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(pages[1]);
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el1) => el1[0]);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const ordersAfterPagingandSorting = (items) => {
    //callback from fetchOrders

    return stableSort(filterFn.fn(items), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  const fetchItems = async (apiname, apipath, setItems) => {
    // apiName = "ordersapi" path = "/orders"
    const apiName = apiname;
    const path = apipath;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        Accept: "application/json",
      },
    };
    return await API.get(apiName, path, myInit)
      .then((res) => {
        setItems(res);
      })
      .then((items) => {
        console.log("success");
      })
      .catch((e) => console.log(e));
  };

  const deleteOrder = async (id, setItems) => {
    const apiName = "ordersapi";
    const path = `/orders/${id}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await API.del(apiName, path, myInit)
      .then((res) => {
        fetchItems("ordersapi", "/orders", setItems);
      })
      .catch((error) => console.log(error.response));
  };

  const fetchOnlineItem = async (url) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        //Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    return await axios.get(url, config).then((response) => response.data);
  };

  const updateItem = async (ordersObj) => {
    const apiName = "ordersapi";
    const path = "/orders";
    const myInit = {
      body: {
        ...ordersObj,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.put(apiName, path, myInit)
      .then((res) => {
        //setRecordForEdit(null);
        //fetchOrders();
        //setTimeout(() => {
        //localStorage.setItem("currentPath", "/");
        //navigate("/");
        //}, 1500);
      })
      .catch((e) => console.log(e));
  };

  const createItem = async (orderObj) => {
    const apiName = "ordersapi";
    const path = "/orders";
    const myInit = {
      body: {
        ...orderObj,
      },
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit)
      .then((res) => {
        //fetchOrders();
        //setTimeout(() => {
        //   localStorage.setItem("currentPath", "/");
        //  navigate("/");
        //}, 1500);
      })
      .catch((error) => console.log(error.response.data));
  };

  return {
    handleSortRequest,
    ordersAfterPagingandSorting,
    handleChangeRowsPerPage,
    handleChangePage,
    fetchItems,
    deleteOrder,
    fetchOnlineItem,
    page,
    orderBy,
    order,
    rowsPerPage,
    pages,
    updateItem,
    createItem,
    setFilterFn,
    filterFn,
  };
};
