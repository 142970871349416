/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:119ec902-c190-4522-b925-f68e25158314",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_dPhxiUKPc",
    "aws_user_pools_web_client_id": "6q09kqsviq716o5m99u8bshlru",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://q6cheiixi8.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "employeeapi",
            "endpoint": "https://2472tw4ai3.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "ordersapi",
            "endpoint": "https://jjywbghi10.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "employeedb-dev",
            "region": "us-east-2"
        },
        {
            "tableName": "ordersdb-dev",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "imagebucket01120621-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
