import { React, useState } from "react";

export const ItemsFormUtils = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("shippingAddress" in fieldValues)
      temp.shippingAddress = fieldValues.shippingAddress
        ? ""
        : "Това поле е задължително";
    if ("exitDate" in fieldValues)
      temp.exitDate = fieldValues.exitDate ? "" : "Това поле е задължително";
    if ("shippingFirstName" in fieldValues)
      temp.shippingFirstName = fieldValues.shippingFirstName
        ? ""
        : "Това поле е задължително";
    if ("shippingLastName" in fieldValues)
      temp.shippingLastName = fieldValues.shippingLastName
        ? ""
        : "Това поле е задължително";
    if ("phone" in fieldValues)
      temp.phone =
        fieldValues.phone &&
        // eslint-disable-next-line
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
          fieldValues.phone
        )
          ? ""
          : "Невалиден телефонен номер";

    if ("product_name1" in fieldValues)
      temp.product_name1 = fieldValues.product_name1
        ? ""
        : "Това поле е задължително";
    if ("product_filling1" in fieldValues)
      temp.product_filling1 = fieldValues.product_filling1
        ? ""
        : "Това поле е задължително";
    if ("product_price1" in fieldValues)
      temp.product_price1 = fieldValues.product_price1
        ? ""
        : "Това поле е задължително";

    if ("status" in fieldValues)
      temp.status = fieldValues.status ? "" : "Това поле е задължително";

    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (true) {
      validate({ [name]: value });
    }
  };
  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };

  const highestId = (orders) => {
    const ids = orders.map((order) => parseInt(order.id, 10));
    const highestId = Math.max(...ids);
    return highestId;
  };

  const sumTotal = (productFields) => {
    const sum = productFields
      .map((item) => item.productPrice * item.productQuantity)
      .reduce((prev, next) => prev + next);
    return sum;
  };

  const productPrice = (values) => {
    const vals = values.products.map((product, index) => {
      product = {
        ...product,
        ["productPrice"]:
          Math.round(
            product.productSize *
              product.productSinglePrice *
              product.productQuantity *
              100
          ) / 100,
      };
      const newValues = Object.assign({}, values, {
        products: product,
      });
      return newValues;
    });
  };

  return {
    values,
    errors,
    setErrors,
    setValues,
    handleInputChange,
    resetForm,
    validate,
    highestId,
    sumTotal,
    productPrice,
  };
};
