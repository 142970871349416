import React from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/bg";

export const CustomDatePicker = (props) => {
  const { name, label, value, onChange, error } = props;

  const convertToDefEventPara = (name, value) => ({
    target: {
      name,
      value,
    },
  });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
      <KeyboardDateTimePicker
        disableToolbar
        inputVariant="outlined"
        label={label}
        ampm={false}
        format={"eeee dd MMM HH:mm"}
        disablePast
        name={name}
        value={value}
        onChange={(date) => onChange(convertToDefEventPara(name, date))}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        {...(error && { error: true, helperText: error })}
      />
    </MuiPickersUtilsProvider>
  );
};
