import "date-fns";

export const fillingItems = [
  { title: "Йогурт с боровинки - едноетажни", additionalCost: 0 },
  { title: "Червено кадифе", additionalCost: 0 },
  { title: "Червено кадифе с малини", additionalCost: 0 },
  { title: "Шоколаов лешник", additionalCost: 0 },
  { title: "Карамел и шоколад", additionalCost: 0 },
  { title: "Рафаело", additionalCost: 0 },
  { title: "Троен шоколад", additionalCost: 0 },
  { title: "Баварски крем", additionalCost: 0 },
  { title: "Крем брюле и орехови блатове", additionalCost: 0 },
  { title: "Мохито с вкус на лайм и мента", additionalCost: 0 },
  { title: "Портокалова с орехи и млечен крем", additionalCost: 0 },
  { title: "Баварси крем с малини или ягоди", additionalCost: 0 },
  { title: "Маскарпоне с малини", additionalCost: 0.8 },
  { title: "Маскарпоне с ягоди", additionalCost: 0.8 },
  { title: "Маскарпоне с нутела", additionalCost: 0.8 },
  { title: "Маскарпоне и солен карамел", additionalCost: 0.8 },
];

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);

export const initialValues = {
  id: "",
  exitDate: tomorrow,
  partialPayment: 0,
  executedBy: "",
  createdBy: "",
  createdAt: "",
  onlineOrderId: "",
  paymentMethod: "forPayment",
  phone: "",
  email: "",
  orderStatus: "Приета",
  comment: "",
  shippingMethod: "takeAway",
  shippingFirstName: "",
  shippingLastName: "",
  shippingAddress: "",
  shippingValue: 0,
  total: 0,
  shippingAbb: "Д",
  executionGroup: "Сладкари",
  products: [
    {
      productId: 1,
      productModel: "",
      productName: "",
      productSize: 1,
      productFilling: "",
      productFillingAdditionalCost: 0,
      productText: "",
      productQuantity: 1,
      productPrice: 0.0,
      productSum: 0.0,
      productSinglePrice: 0.0,
      productCategory: "standard",
      images: [],
    },
  ],
};

export const categoryList = [
  { id: "standard", title: "Стандартна" },
  { id: "nonStandard", title: "Нестандартна" },
];

export const executionGroupList = [
  { id: "Сладкари", title: "Сладкари" },
  { id: "Декоратори", title: "Декоратори" },
];

export const numberOfPiecesList = [
  { id: 8, title: "8" },
  { id: 10, title: "10" },
  { id: 12, title: "12" },
  { id: 16, title: "16" },
  { id: 20, title: "20" },
  { id: 26, title: "26" },
  { id: 30, title: "30" },
];

export const deliveryMethodList = [
  { id: "takeAway", title: "Вземи от наша сладкарница" },
  { id: "delivery", title: "ДОСТАВКА ЗА ГР.СОФИЯ" },
];

export const takeAwayLocationList = [
  {
    id: "nishava",
    title: "ул. Нишава 153",
  },
  {
    id: "kostenski",
    title: "ул. Костенски водопад 59",
  },
  {
    id: "kableshkov",
    title: "бул. Тодор Каблешков 21",
  },
];

export const paymentMethodList = [
  { id: "payed", title: "Платена" },
  { id: "forPayment", title: "Плащане при доставка" },
];

export const statusList = [
  { id: "Приета", title: "Приета" },
  { id: "Изпълнява се", title: "Изпълнява се" },
  { id: "Изпълнена", title: "Изпълнена" },
  { id: "Заготовка", title: "Заготовка" },
];
