import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./ItemsPageStyles";
import { useNavigate } from "react-router-dom";
import { InputAdornment, Paper, Toolbar } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { TableSortLabel } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import { format } from "date-fns";
import deLocale from "date-fns/locale/bg";
import xml2js from "xml2js";
import unorm from "unorm";
import { Button } from "@material-ui/core";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { Storage } from "aws-amplify";
import { CustomPopup } from "../CustomComponents/CustomPopup";
import Carousel from "react-material-ui-carousel";
import { ItemsPageUtils } from "./ItemsPageUtils";
import { ItemsListUtils } from "../ItemsList/ItemsListUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import Divider from "@mui/material/Divider";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

export const ItemsPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    setRedOnly,
    setItems,
    items,
    headCells,
    group,
    setRecordForEdit,
    groups,
  } = props;

  const matches = useMediaQuery("(max-width:600px)");

  const headCellsMobile = [
    { id: "id", label: "№" },
    { id: "orderStatus", label: "Статус" },
    { id: "actions", label: "Действия", disableSorting: true },
  ];

  const pages = [5, 10, 15];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[1]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [images, setImages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [urls, setUrls] = useState([]);
  //const [exitDate, setExitDate] = useState();
  const [onlineRecords, setOnlineRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [groupsArr, setGroupsArr] = useState([]);
  const { deleteOrder, fetchItems, getAll } = ItemsPageUtils();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setPage(0);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const ordersAfterPagingandSorting = () => {
    return stableSort(filterFn.fn(items), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el1) => el1[0]);
  };

  const handleSearch = (e) => {
    const target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else
          return items.filter(
            (x) =>
              x.orderStatus
                .toLowerCase()
                .includes(target.value.toLowerCase()) ||
              x.exitDate.includes(target.value) ||
              x.id.includes(target.value) ||
              x.executedBy.toLowerCase().includes(target.value) ||
              x.products[0].productName.toLowerCase().includes(target.value) ||
              x.shippingFirstName.toLowerCase().includes(target.value) ||
              x.shippingLastName.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const convertDate = (custDate) => {
    const k = custDate.split(".");
    const p = `${k[2]}-${k[1]}-${k[0]}`;
    return p;
  };

  const { createItem } = ItemsListUtils();
  const highestId = (orders) => {
    const ids = orders.map((order) => parseInt(order.id, 10));
    const highestId = Math.max(...ids);
    return highestId;
  };

  //todo add bakers to the filter
  const ordersAfterGroupSorting = () => {
    return groupsArr.includes("sladkari")
      ? ordersAfterPagingandSorting().filter(
          (item) => item.executionGroup === "Сладкари"
        )
      : groupsArr.includes("dekoratori")
      ? ordersAfterPagingandSorting().filter(
          (item) => item.executionGroup === "Декоратори"
        )
      : ordersAfterPagingandSorting();
  };

  const ordersAfterStatusSorting = () => {
    return ordersAfterGroupSorting().filter(
      (item) => item.orderStatus !== "Изпълнена"
    );
  };

  useEffect(() => {
    groups().then((res) => setGroupsArr(res));

    setUrls([]);
    const arr = images.map((img) => {
      return Storage.get(img);
    });
    Promise.all(arr).then((vals) => setUrls(vals));

    const parser = new xml2js.Parser({ trim: true });

    getAll("https://www.tortibrimeks.bg/export/orders.xml")
      .then((response) => {
        parser.parseString(response, function (err, result) {
          //console.log("orders", result.itemlist.item[1].products[0].product);
          if (result.itemlist.item !== undefined) {
            const i = result.itemlist.item.map((order) => ({
              comment: order.comment[0],
              createdBy: "",
              executedBy: "",
              id: "", //edit
              email: order.email[0],
              phone: order.telephone[0],
              orderStatus: "Приета",
              paymentMethod: order.payment_method[0],
              partialPayment: 0,
              shippingAddress: unorm
                .nfc(order.shipping_method[0])
                .toString()
                .includes(unorm.nfc("наша"))
                ? unorm
                    .nfc(order.shipping_method[0])
                    .toString()
                    .includes(unorm.nfc("Нишава"))
                  ? "nishava"
                  : unorm
                      .nfc(order.shipping_method[0])
                      .toString()
                      .includes(unorm.nfc("Тодор"))
                  ? "kableshkov"
                  : "kostenski"
                : order.shipping_address_1[0],
              onlineOrderId: order.order_id[0],
              shippingFirstName: order.shipping_firstname[0],
              shippingLastName: order.shipping_lastname[0],
              shippingMethod: unorm
                .nfc(order.shipping_method[0])
                .toString()
                .includes(unorm.nfc("наша"))
                ? "takeAway"
                : "delivery",
              shippingAbb: unorm
                .nfc(order.shipping_method[0])
                .toString()
                .includes(unorm.nfc("наша"))
                ? unorm
                    .nfc(order.shipping_method[0])
                    .toString()
                    .includes(unorm.nfc("Тодор"))
                  ? "Т"
                  : unorm
                      .nfc(order.shipping_method[0])
                      .toString()
                      .includes(unorm.nfc("Костенски"))
                  ? "К"
                  : "Н"
                : "Д",
              shippingValue: parseFloat(order.shipping_value[0]),
              total: parseFloat(order.total_value[0]),
              createdAt: order.date_added[0],
              exitDate: "",

              products: order.products[0].product.map((product, index) => ({
                productName: product.name[0],
                productCategory: "standard",
                productModel: product.model[0].split("-")[0].replace(/\s/g, ""),
                productQuantity: parseInt(product.quantity[0]),
                productPrice: parseFloat(product.price[0]),
                productSum: parseFloat(product.total[0]),
                images: [],
                productExecutionGroup: product.model[0]
                  .split("-")[1]
                  .replace(/\s/g, ""),
                productText: product.option
                  ? product.option
                      .filter((x) => x.name === "Надпис (по-желание)")
                      .map((o) => o.value[0])[0]
                  : "",
                productSize: product.option
                  ? product.option
                      .filter((x) => x.name[0] === "Изберете размер на торта")
                      .map((o) => parseInt(o.value[0].split(" ")[0]))
                  : "",
                productFilling: product.option
                  ? product.option
                      .filter((x) => x.name[0] === "Пълнеж на торта")
                      .map((o) => o.value[0])[0]
                  : "",

                productExitDate: product.option
                  ? convertDate(
                      product.option
                        .filter((x) => x.type[0] === "date")
                        .map((o) => o.value[0])[0]
                    )
                  : "",
              })),
            }));

            const g = i.map((order) => {
              return {
                ...order,
                exitDate: order.products[0].productExitDate,
                executionGroup:
                  order.products[0].productExecutionGroup === "D"
                    ? "Декоратори"
                    : "Сладкари",
              };
            });
            setOnlineRecords(g);
          }

          //updateOnlineOrders();
          fetchItems("ordersapi", "/orders", setItems).then((res) =>
            setIsLoading(false)
          );
        });
      })
      .catch((err) => console.log(err));
  }, [images]);

  const updateOnlineOrders = () => {
    if (items.length > 0) {
      const arr = items.map((item) => item.onlineOrderId);
      const o = onlineRecords.filter(
        (record) => !arr.includes(record.onlineOrderId)
      );
      o.forEach((order) => {
        const nextId = highestId(items);
        order.id = (nextId + 1).toString();
        createItem(order);
        fetchItems("ordersapi", "/orders", setItems);
      });
    }
  };
  updateOnlineOrders();

  return (
    <div>
      {isLoading ? (
        <CircularProgress
          className={classes.progress}
          color="primary"
          size="6rem"
        />
      ) : (
        <Paper className={classes.pageContent}>
          <Toolbar>
            <TextField
              variant="outlined"
              label="Търсене"
              className={classes.searchInput}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
            {group.includes("employees") ? (
              ""
            ) : (
              <Button
                className={classes.newButton}
                text="Добави"
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  navigate("/ordersform"); //set route to /ordersform
                  setRecordForEdit(null);
                  setRedOnly(false);
                }}
              >
                {matches ? "" : "Добави"}
              </Button>
            )}
          </Toolbar>
          <Table
            className={classes.table}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              {matches ? (
                ""
              ) : (
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                    >
                      {headCell.disableSorting ? (
                        headCell.label
                      ) : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          onClick={() => {
                            handleSortRequest(headCell.id);
                          }}
                          direction={orderBy === headCell.id ? order : "asc"}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {ordersAfterStatusSorting().map((item) => (
                <>
                  <TableRow key={item.id}>
                    <TableCell>{item.id}</TableCell>
                    <TableCell>{item.orderStatus}</TableCell>
                    <TableCell>{item.executedBy}</TableCell>
                    <TableCell>
                      {item.exitDate
                        ? format(new Date(item.exitDate), "eee dd MMM", {
                            locale: deLocale,
                          })
                        : ""}
                    </TableCell>
                    <TableCell>
                      {matches
                        ? item.products[0].productName.substring(0, 25) + "..."
                        : item.products[0].productName}
                    </TableCell>
                    <TableCell>{item.shippingAbb}</TableCell>
                    <TableCell>
                      {matches
                        ? "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0" +
                          item.executionGroup +
                          "\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0"
                        : item.executionGroup}
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.actionButton}
                        color="primary"
                        onClick={() => {
                          let imgs = [];

                          setOpenPopup(true);
                          setRedOnly(false);
                          const products = [...item.products];
                          try {
                            products.forEach((product) => {
                              product.images.forEach((i) => {
                                imgs.push(i.fileName);
                              });
                            });
                          } catch {
                            console.log();
                          }

                          //console.log(item);
                          setImages(imgs);
                        }}
                      >
                        <PhotoCamera fontSize="small" />
                      </Button>

                      <Button
                        className={classes.actionButton}
                        color="font"
                        onClick={() => {
                          setRecordForEdit(item);
                          setRedOnly(true);
                          navigate("/orderdetails");
                        }}
                      >
                        <MoreHorizOutlinedIcon fontSize="small" />
                      </Button>

                      <Button
                        className={classes.actionButton}
                        color="main"
                        onClick={() => {
                          setRecordForEdit(item);
                          setRedOnly(false);
                          navigate("/editorder");
                        }}
                      >
                        <EditOutlinedIcon fontSize="small" />
                      </Button>

                      {group.includes("employees") ? (
                        ""
                      ) : (
                        <Button
                          className={classes.deleteButton}
                          color="secondary"
                          onClick={() => {
                            deleteOrder(item.id, setItems);
                            navigate("/");
                          }}
                        >
                          <DeleteOutlineIcon fontSize="small" />
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                  {matches ? <Divider /> : ""}
                </>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            className={classes.pagination}
            component="div"
            page={page}
            rowsPerPageOptions={pages}
            rowsPerPage={rowsPerPage}
            count={items.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <CustomPopup
            openPopup={openPopup}
            setOpenPopup={setOpenPopup}
            title="Изображения"
          >
            <Carousel>
              {urls.map((item, i) => (
                <img alt={""} key={i} src={item} />
              ))}
            </Carousel>
          </CustomPopup>
        </Paper>
      )}
    </div>
  );
};
