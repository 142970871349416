import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
    ["@media (max-width:600px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "0px",
    },
  },

  table: {
    marginTop: theme.spacing(3),
    "& thead th": {
      fontWeight: "600",
      color: "#ffff",
      backgroundColor: theme.palette.primary.light,
    },
    "& tbody td": {
      fontWeight: "300",
    },
    "& tbody tr:hover": {
      backgroundColor: "#fffbf2",
      cursor: "pointer",
    },
    ["@media (max-width:600px)"]: {
      "& tr": {
        width: "100%",
        display: "flex",
        flexFlow: "row",
        flexWrap: "wrap",
      },
      "& tr td": {
        flex: "1 0 13%",
      },

      "& .MuiTableCell-root": {
        borderBottom: "none",
      },
    },
  },
  pagination: {
    ["@media (max-width:600px)"]: {
      "& .MuiTablePagination-caption": {
        display: "none !important",
      },
    },
  },

  searchInput: {
    width: "75%",
    ["@media (max-width:600px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "60%",
    },
  },
  newButton: {
    position: "absolute",
    right: "10px",
    padding: "15px",
    margin: theme.spacing(1),
    label: {
      textTransform: "none",
    },
    "& .MuiButton-startIcon": {
      margin: "0px",
    },
  },

  actionButton: {
    minWidth: 0,
    margin: theme.spacing(0.5),

    "& .MuiButton-label": {
      color: theme.palette.primary.main,
    },
  },
  deleteButton: {
    minWidth: 0,
    margin: theme.spacing(0.5),

    "& .MuiButton-label": {
      color: theme.palette.secondary.main,
    },
  },

  progress: {
    marginLeft: "45%",
    marginTop: "20%",
  },
}));
