import { API, Auth } from "aws-amplify";
import axios from "axios";

export const ItemsPageUtils = () => {
  const fetchItems = async (apiname, apipath, setItems) => {
    // apiName = "ordersapi" path = "/orders"
    const apiName = apiname;
    const path = apipath;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
        Accept: "application/json",
      },
    };
    return await API.get(apiName, path, myInit)
      .then((res) => {
        setItems(res);
      })
      .catch((e) => console.log(e));
  };

  const deleteOrder = async (id, setItems) => {
    const apiName = "ordersapi";
    const path = `/orders/${id}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };
    return await API.del(apiName, path, myInit)
      .then((res) => {
        fetchItems("ordersapi", "/orders", setItems);
      })
      .catch((error) => console.log(error.response));
  };

  const getAll = async (url) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        //"Access-Control-Allow-Origin": "*",
        //Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    };
    return await axios.get(url, config).then((response) => response.data);
  };

  return {
    fetchItems,
    deleteOrder,
    getAll,
  };
};
