import { React, useState, useEffect } from "react";
import { Grid, InputAdornment } from "@material-ui/core";
import { useForm, Form } from "../components/general/useForm";
import { Controls } from "../components/controls/controls";
import { Alert } from "@material-ui/lab";
import { Auth } from "aws-amplify";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import "date-fns";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

const menuItems = [
  { title: "Торта Виена", model: "1049", price: 4.9 },
  { title: "Торта Трюфел", model: "1048", price: 4.9 },
  { title: "Торта Столетов", model: "1046", price: 4.9 },
  { title: "Тирамису", model: "1046", price: 5.5 },
  { title: "Портокалов сюрприз", model: "1044", price: 4.9 },
  { title: "Плодова пита", model: "1043", price: 4.9 },
  { title: "Торта Павлова", model: "1042", price: 5.5 },
  { title: "Морковен чийз кейк", model: "1041", price: 4.9 },
  { title: "Торта Маскарпоне", model: "1040", price: 4.9 },
  { title: "Торта Линдт", model: "1039", price: 5.4 },
  { title: "Еклерова с боровинки", model: "1038", price: 4.9 },
];

const initialValues = {
  id: "",
  exitDate: `${new Date().getDate() + 1}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`,
  partialPayment: 0,
  executedBy: "",
  createdBy: "",
  createdAt: "",
  onlineOrderId: "",
  paymentMethod: "forPayment",
  phone: "",
  email: "",
  orderStatus: "received",
  comment: "",
  shippingMethod: "takеAway",
  shippingFirstName: "",
  shippingLastName: "",
  shippingAddress: "",
  shippingValue: 0,
  total: 0,
};

const numberOfPiecesList = [
  { id: 8, title: "8" },
  { id: 10, title: "10" },
  { id: 12, title: "12" },
  { id: 16, title: "16" },
  { id: 20, title: "20" },
  { id: 26, title: "26" },
  { id: 30, title: "30" },
];

const deliveryMethodList = [
  { id: "takеAway", title: "Вземи от наша сладкарница" },
  { id: "delivery", title: "ДОСТАВКА ЗА ГР.СОФИЯ" },
];

const takeAwayLocationList = [
  {
    id: "nishava",
    title: "ул. Нишава 153",
  },
  {
    id: "kostenski",
    title: "ул. Костенски водопад 59",
  },
  {
    id: "kableshkov",
    title: "бул. Тодор Каблешков 21",
  },
];

const paymentMethodList = [
  { id: "payed", title: "Платена" },
  { id: "forPayment", title: "Плащане при доставка" },
];

const OrdersForm = (props) => {
  const [success, setSuccess] = useState();
  const [error, setError] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [currUser, setCurrUser] = useState();
  const [ifGroupUsers, setIfGroupUsers] = useState(false);
  const [productFields, setProductFields] = useState([
    {
      productId: 1,
      productModel: "",
      productName: "",
      productSize: 12,
      productFilling: "",
      productText: "",
      productQuantity: 1,
      productPrice: 0,
      productSum: 0,
      productSinglePrice: 0,
    },
  ]);

  const { readOnly, recordForEdit, addOne, editOne, statusList, orders } =
    props;

  const getCurrUser = async () => {
    const tokens = await Auth.currentSession();
    const userName = tokens.getIdToken().payload["cognito:username"];
    setCurrUser(userName);
  };

  const ifUserGroupIs = async (group) => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];

    return groups.includes(group);
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("shippingAddress" in fieldValues)
      temp.shippingAddress = fieldValues.shippingAddress
        ? ""
        : "Това поле е задължително";
    if ("exitDate" in fieldValues)
      temp.exitDate = fieldValues.exitDate ? "" : "Това поле е задължително";
    if ("shippingFirstName" in fieldValues)
      temp.shippingFirstName = fieldValues.shippingFirstName
        ? ""
        : "Това поле е задължително";
    if ("shippingLastName" in fieldValues)
      temp.shippingLastName = fieldValues.shippingLastName
        ? ""
        : "Това поле е задължително";
    if ("phone" in fieldValues)
      temp.phone =
        fieldValues.phone &&
        // eslint-disable-next-line
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(
          fieldValues.phone
        )
          ? ""
          : "Невалиден телефонен номер";

    if ("product_name1" in fieldValues)
      temp.product_name1 = fieldValues.product_name1
        ? ""
        : "Това поле е задължително";
    if ("product_filling1" in fieldValues)
      temp.product_filling1 = fieldValues.product_filling1
        ? ""
        : "Това поле е задължително";
    if ("product_price1" in fieldValues)
      temp.product_price1 = fieldValues.product_price1
        ? ""
        : "Това поле е задължително";

    if ("status" in fieldValues)
      temp.status = fieldValues.status ? "" : "Това поле е задължително";

    setErrors({ ...temp });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    useForm(initialValues, true, validate);

  const highestId = (orders) => {
    const ids = orders.map((order) => parseInt(order.id, 10));
    const highestId = Math.max(...ids);
    return highestId;
  };

  const sumTotal = () => {
    const sum = productFields
      .map((item) => item.productPrice * item.productQuantity)
      .reduce((prev, next) => prev + next);
    return sum;
  };

  useEffect(() => {
    getCurrUser();
    ifUserGroupIs("employees")
      .then((res) => setIfGroupUsers(res))
      .catch(console.error);

    setValues({
      ...values,
      ["total"]: sumTotal(),
    });

    if (recordForEdit !== null) {
      setValues({
        ...recordForEdit,
      });
      setProductFields([...recordForEdit.products]);
    }
    // eslint-disable-next-line
    console.log(values);
  }, []);

  const registerHandler = async (e) => {
    e.preventDefault();

    getCurrUser();
    values.createdBy = currUser;
    //console.log(values);

    if (validate()) {
      try {
        if (values.id === "") {
          const nextId = highestId(orders) + 1;
          values.id = nextId.toString();
          addOne(values);
          setSuccessMessage("Успешна регистрация на поръчка");
        } else {
          getCurrUser();
          values.executedBy = "";
          values.executedBy = currUser;
          editOne(values);
          setSuccessMessage("Записът за поръчката е обновен");
        }

        setSuccess("success");
        resetForm();

        setTimeout(() => {
          setSuccess("");
        }, 3000);
      } catch (error) {
        setError(error.response.data.error);
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
  };

  const isReadOnly = () => {
    if (readOnly || ifGroupUsers) {
      return true;
    }
    return false;
  };

  return (
    <Form onSubmit={registerHandler}>
      {success && <Alert severity="success">{successMessage}</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <Typography variant="h4" gutterBottom>
        Детайли за поръчката
      </Typography>
      <Box m={2}>
        <Divider />
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.RadioGroup
            name="paymentMethod"
            label="Статус на плащане"
            value={values.paymentMethod}
            onChange={handleInputChange}
            items={paymentMethodList}
            readOnly={isReadOnly()}
          />

          <Controls.RadioGroup
            name="shippingMethod"
            label="Метод за доставка"
            value={values.shippingMethod}
            onChange={handleInputChange}
            items={deliveryMethodList}
            error={errors.shippingMethod}
            readOnly={isReadOnly()}
          />
          {values.shippingMethod === "takеAway" ? (
            <Controls.Select
              name="shippingAddress"
              label="Локация за взимане"
              value={values.shippingAddress}
              onChange={handleInputChange}
              options={takeAwayLocationList}
              error={errors.shippingAddress}
              readOnly={isReadOnly()}
            />
          ) : values.shippingMethod === "delivery" ? (
            <Controls.Input
              name="shippingAddress"
              label="Адрес за доставка"
              value={values.shippingAddress}
              onChange={handleInputChange}
              error={errors.shippingAddress}
              inputProps={{
                readOnly: Boolean(isReadOnly()),
              }}
            />
          ) : (
            ""
          )}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controls.Select
            name="orderStatus"
            label="Статус"
            value={values.orderStatus}
            onChange={handleInputChange}
            options={statusList}
            error={errors.orderStatus}
            readOnly={readOnly}
          />
          <Controls.DatePicker
            name="exitDate"
            label="Дата на издаване"
            value={values.exitDate}
            onChange={handleInputChange}
            readOnly={isReadOnly()}
            error={errors.orderStatus}
          />
        </Grid>
      </Grid>
      <Box m={2}>
        <Typography variant="h4" gutterBottom>
          Упътвания
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Controls.Input
            name="comment"
            label="Допълнителна информация"
            value={values.comment}
            onChange={handleInputChange}
            error={errors.comment}
            inputProps={{
              readOnly: Boolean(isReadOnly()),
            }}
            multiline
            minRows={4}
          />
        </Grid>
      </Grid>
      <Box m={2}>
        <Typography variant="h6" gutterBottom>
          Данни на клиента
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            name="shippingFirstName"
            label="Име на клиент"
            value={values.shippingFirstName}
            onChange={handleInputChange}
            error={errors.shippingFirstName}
            inputProps={{
              readOnly: Boolean(isReadOnly()),
            }}
          />
          <Controls.Input
            name="shippingLastName"
            label="Фамилия на клиент"
            value={values.shippingLastName}
            onChange={handleInputChange}
            error={errors.shippingLastName}
            inputProps={{
              readOnly: Boolean(isReadOnly()),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            name="email"
            label="Имейл"
            value={values.email}
            onChange={handleInputChange}
            error={errors.email}
            inputProps={{
              readOnly: Boolean(isReadOnly()),
              disabled: Boolean(isReadOnly()),
            }}
          />
          <Controls.Input
            name="phone"
            label="Телефон"
            value={values.phone}
            onChange={handleInputChange}
            error={errors.phone}
            inputProps={{
              readOnly: Boolean(isReadOnly()),
              disabled: Boolean(isReadOnly()),
            }}
          />
        </Grid>
      </Grid>

      {productFields.map((productField, index) => {
        return (
          <div>
            <Grid container key={index}>
              <Grid item xs={12} sm={6}>
                <Box m={2}>
                  <Typography variant="h6" gutterBottom>
                    {"Данни за продукт" +
                      " " +
                      (productField.productId + index).toString()}
                  </Typography>
                </Box>
                <Autocomplete
                  id={"productName" + index.toString()}
                  disabled={isReadOnly()}
                  options={menuItems}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("title")) {
                      return option.title;
                    }
                    return option;
                  }}
                  onChange={(event, newValue) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productId"]: productField.productId + index,
                      ["productName"]: newValue.title,
                      ["productModel"]: newValue.model,
                      ["productSinglePrice"]: newValue.price,
                      ["productPrice"]:
                        Math.round(
                          productFields[index].productSize *
                            newValue.price *
                            100
                        ) / 100,
                      ["productSum"]:
                        productFields[index].productPrice *
                        productFields[index].productQuantity,
                    };

                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  value={productFields[index].productName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Име на продукта"
                      variant="outlined"
                    />
                  )}
                />
                <Autocomplete
                  id={"productModel" + index.toString()}
                  options={menuItems}
                  disabled={isReadOnly()}
                  getOptionLabel={(option) => {
                    if (option.hasOwnProperty("model")) {
                      return option.model;
                    }
                    return option;
                  }}
                  onChange={(event, newValue) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productId"]: productField.productId + index,
                      ["productName"]: newValue.title,
                      ["productModel"]: newValue.model,
                      ["productSinglePrice"]: newValue.price,
                      ["productPrice"]:
                        Math.round(
                          productFields[index].productSize *
                            newValue.price *
                            100
                        ) / 100,
                      ["productSum"]:
                        productFields[index].productPrice *
                        productFields[index].productQuantity,
                    };

                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });

                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  value={productFields[index].productModel}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Номер модел"
                      variant="outlined"
                    />
                  )}
                />
                <Controls.Input
                  name="productFilling"
                  label="Пълнеж на продукта"
                  value={productFields[index].productFilling}
                  onChange={(e) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productFilling"]: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  error={errors[productFields[index].productFilling]}
                  inputProps={{
                    readOnly: Boolean(isReadOnly()),
                    disabled: Boolean(isReadOnly()),
                  }}
                />
                <Controls.Input
                  name="productText"
                  label="Надпис по желание"
                  value={productFields[index].productText}
                  onChange={(e) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productText"]: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  error={errors[productFields[index].productText]}
                  inputProps={{
                    readOnly: Boolean(isReadOnly()),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box m={2}>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      setProductFields([
                        ...productFields,

                        {
                          productId: 1,
                          productModel: "",
                          productName: "",
                          productSize: 12,
                          productFilling: "",
                          productText: "",
                          productQuantity: 1,
                          productPrice: 0,
                          productSum: 0,
                          productSinglePrice: 0,
                        },
                      ]);
                    }}
                  >
                    <AddIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      const vals = [...productFields];
                      if (productFields.length > 1) {
                        vals.splice(index, 1);
                        setProductFields(vals);
                      }
                    }}
                  >
                    <RemoveIcon fontSize="small" />
                  </Controls.ActionButton>
                </Box>

                <Autocomplete
                  id="productSize"
                  disabled={isReadOnly()}
                  options={numberOfPiecesList}
                  defaultValue={numberOfPiecesList[0]}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, newValue) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productSize"]: newValue.title,
                      ["productPrice"]:
                        Math.round(
                          newValue.id *
                            productFields[index].productSinglePrice *
                            100
                        ) / 100,
                      ["productSum"]:
                        productFields[index].productPrice *
                        productFields[index].productQuantity,
                    };

                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });

                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  value={productFields[index].productSize}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Брой парчета"
                      variant="outlined"
                      defaultValue="12"
                    />
                  )}
                />
                <Controls.Input
                  name="productQuantity"
                  label="Количество"
                  value={productFields[index].productQuantity}
                  onChange={(e) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productQuantity"]: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  error={errors[productFields[index].productQuantity]}
                  inputProps={{
                    readOnly: Boolean(isReadOnly()),
                  }}
                />
                <Controls.Input
                  name="productPrice"
                  label="Цена на продукта"
                  value={productFields[index].productPrice}
                  onChange={(e) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productPrice"]: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  error={errors[productFields[index].productPrice]}
                  inputProps={{
                    readOnly: Boolean(isReadOnly()),
                  }}
                />
                <Controls.Input
                  name="productSum"
                  label="Сума"
                  value={
                    Math.round(
                      productFields[index].productPrice *
                        productFields[index].productQuantity *
                        100
                    ) / 100
                  }
                  onChange={(e) => {
                    const productValues = [...productFields];
                    productValues[index] = {
                      ...productValues[index],
                      ["productSum"]: e.target.value,
                    };
                    const newValues = Object.assign({}, values, {
                      products: productValues,
                    });
                    setValues(newValues);
                    setProductFields(productValues);
                  }}
                  error={errors[productFields[index].productSum]}
                  inputProps={{
                    readOnly: Boolean(isReadOnly()),
                  }}
                />
              </Grid>
            </Grid>
          </div>
        );
      })}

      <Grid container>
        <Grid item xs={12} sm={6}></Grid>
        <Grid item xs={12} sm={6}>
          <Box m={3}>
            <Controls.Input
              name="shippingValue"
              label="Сума за доставка"
              value={values.shippingValue}
              onChange={handleInputChange}
              error={errors.shippingValue}
              type="number"
              inputProps={{
                readOnly: Boolean(isReadOnly()),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
            <Controls.Input
              name="partialPayment"
              label="Капаро"
              value={values.partialPayment}
              onChange={handleInputChange}
              error={errors.partialPayment}
              type="number"
              inputProps={{
                readOnly: Boolean(isReadOnly()),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
            <Controls.Input
              name="total"
              label="Тотал"
              value={parseFloat(
                values.total + values.shippingValue * 1 - values.partialPayment
              )}
              onChange={handleInputChange}
              error={errors.total}
              type="number"
              inputProps={{
                readOnly: Boolean(true),
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">лв</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
      </Grid>

      {!readOnly ? (
        <div>
          <Controls.Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            text="Регистрирай"
          />
          <Controls.Button
            variant="contained"
            color="default"
            size="large"
            text="Ресет"
            onClick={resetForm}
          />
        </div>
      ) : (
        ""
      )}
    </Form>
  );
};
//todo add other products
export default OrdersForm;
